import React from "react";
import { graphql } from "gatsby";
import isString from "lodash/isString";

import NotFoundPage from "pages/404";
import SEO from "components/SEO";
import SingleVendorPage from "vendors/SingleVendorPage";

import { groupOpeningHours } from "utils";
import getImage from "utils/getImage";

const VendorPage = ({
  data: {
    strapiVendor,
    strapiProperty,
    similarExperiences,
    requestForm,
    strapiRecommendsConfig,
  },
}) => {
  if (strapiVendor === null) {
    return <NotFoundPage />;
  }

  const vendor = {
    ...strapiVendor,
    team: strapiVendor.meet_the && {
      title: strapiVendor.meet_the.title,
      profiles:
        strapiVendor.meet_the?.profiles?.map((profile) => ({
          ...profile,
          description: profile.description.data.description,
          image: getImage(profile.image),
        })) ?? [],
    },
    openingHours: groupOpeningHours(strapiVendor.openingHours),
    contactInfo: {
      ...strapiVendor.contactInfo,
      city: strapiVendor.contactInfo?.address?.city ?? "",
    },
    gallery: strapiVendor.gallery
      .map((node) => {
        if (node?.video) {
          return {
            url: node.video.localFile?.publicURL,
            type: "video",
          };
        }
        return {
          image: getImage(node?.image),
          type: "image",
        };
      })
      .filter((media) => media.url || media.image)
      .sort((media1, media2) => {
        if (media1.type === "video") {
          return media2.type === "video" ? 0 : -1;
        }
        if (media2.type === "video") {
          return 1;
        }
        return 0;
      }),

    hero_image: getImage(strapiVendor.hero_image),
    property: strapiProperty,
  };

  console.log(vendor.openingHours)
  console.log(strapiVendor.openingHours)
  const relatedVendors = similarExperiences.nodes.map((node) => ({
    ...node,
    city: node.contactInfo?.address?.city,
    country: node.contactInfo?.address?.country ?? "",

    image: getImage(
      node.hero_image,
      strapiRecommendsConfig?.defaultVendorImage
    ),
  }));

  return (
    <SingleVendorPage
      relatedVendors={relatedVendors}
      vendor={vendor}
      city={strapiProperty.city}
      requestForm={requestForm}
    />
  );
};

export const query = graphql`
  query VendorPageQuery(
    $slug: String
    $propertyId: Int
    $vendorTags: [Int]
    $propertyCityGroup: [Int]
    $requestFormId: Int
  ) {
    strapiVendor(slug: { eq: $slug }) {
      axpVendorId: axp_vendor_id
      requestFormId: request_form_id
      name
      description
      property_id
      contactInfo: contact_info {
        ...contactInfoFragment
      }
      openingHours: opening_hours {
        ...openingHoursFragment
      }
      insiderTip: insider_tip
      gallery {
        ...galleryFragment
      }
      hero_image {
        ...strapiMediaFragment
      }
      availabilityCheckEnabled: availability_check_enabled
      prices {
        currency
        from
        to
        unit
      }
      tags: vendor_tags {
        id: strapi_id
        title
      }
      meet_the {
        ...meetTheTeamFragment
      }
      information_sections {
        ...informationSectionsFragment
      }
    }
    strapiProperty(strapi_id: { eq: $propertyId }) {
      city {
        id: strapi_id
        name
        slug
      }
    }
    similarExperiences: allStrapiVendor(
      filter: {
        property_id: { in: $propertyCityGroup }
        vendor_tags: { elemMatch: { strapi_id: { in: $vendorTags } } }
        slug: { ne: $slug }
      }
    ) {
      nodes {
        axpVendorId: axp_vendor_id
        slug
        name
        description
        hero_image {
          ...strapiMediaFragment
        }
        contactInfo: contact_info {
          address {
            city
            country
          }
        }
        tags: vendor_tags {
          id: strapi_id
        }
        propertyId: property_id
      }
    }
    requestForm: strapiRequestForm(strapi_id: { eq: $requestFormId }) {
      type: request_name
    }
    strapiRecommendsConfig {
      defaultVendorImage: default_vendor_image {
        ...strapiMediaFragment
      }
    }
  }
`;

export default VendorPage;

export const Head = ({ data }) => (
  <SEO title={data?.strapiVendor?.name || "Vendor"} />
);
